document.addEventListener 'turbo:load', ->
  if !document.querySelector('.code-block .mathjax')
    return

  if window.MathJax
    MathJax.Hub.Queue(["Typeset", MathJax.Hub])
  else
    window.MathJax =
      showProcessingMessages: false
      tex2jax:
        inlineMath: [ ['$','$'], ["\\(","\\)"] ]
        displayMath: [ ['$$','$$'], ["\\[","\\]"] ]
        processEscapes: true
        processEnvironments: false
        processRefs: false
        skipTags: ["script","noscript","style","textarea","pre","code","annotation","annotation-xml"]

    $.getScript 'https://assets.esa.io/vendor/MathJax/2.6.1/MathJax.js?config=TeX-AMS_HTML-full'
