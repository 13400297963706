jQuery ->
  star = new Star

class Star
  selection: ''
  constructor: ->
    @init()

  init: ->
    @highlight_my_footprint()
    @listen()

  highlight_my_footprint: ->
    nickname = $('body').data('current-user-nickname')
    $icon_footprint = $(".icon-footprint[data-user-nickname='#{nickname}']")
    $icon_footprint.addClass('my')
    if $('body').hasClass('post')
      $icon_footprint.closest('.star-content').find('.edit-star .edit i').removeClass('hidden')

  listen: ->
    $(document).on 'turbo:load', @highlight_my_footprint
    @listen_click_star()
    @listen_post()
    @listen_touch_end()
    @listen_edit_star()

  listen_click_star: ->
    $(document).on 'click', 'a.post-star', (e) =>
      $('ul.stars').append('<li class="star"><i class="icon-footprint pending" /></li>')

  listen_post: ->
    $(document).on 'ajax:before', 'a.post-star, a.put-star', (e) =>
      if @selection
        selection = @selection
        @selection = ''
      else
        selection = $.selection('html')
      params = new URLSearchParams(content: selection)
      e.currentTarget.dataset.params = params.toString()

    $(document).on 'ajax:success', 'a.post-star, a.put-star', (e) =>
      if html = e.originalEvent.detail[0]
        $('.star-gazers').html($(html).find('body').html())
        if $('body').data('current-user-nickname')
          @highlight_my_footprint()
        else
          $('.post-star[data-method=post]').replaceWith($('.hidden .post-star'))
        setTimeout ->
          $('ul.stars.star-toggle .trigger:first').click()
        , 400
      return

    $(document).on 'ajax:error', 'a.post-star, a.put-star', (e) =>
      window.location.reload()

  listen_touch_end:->
    $(document).on 'touchend', (e) =>
      selection = $.selection('html')
      if selection
        @selection = selection

  listen_edit_star: ->
    $(document).on 'click', '.edit-star .edit', (e) =>
      $(e.currentTarget)
        .addClass('hidden')
        .closest('.phrase').find('.text').addClass('edit-mode')
      $('.post-star').addClass('hidden')
      $('.put-star').removeClass('hidden')
