jQuery ->
  zapping = new Zapping

class Zapping
  selection: ''
  nicknames: []
  constructor: ->
    @init()

  init: ->
    @listen()
    @listen_touch_end()

  listen: ->
    $(document).on 'click', '.zapping-button a', (e) =>
      $(e.currentTarget).addClass('clicked')

    $(document).on 'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd', '.zapping-button a', (e) ->
      $(e.currentTarget).removeClass('clicked')

    $(document).on 'click', ".zapping-button a:not([href^='#'])", (e) =>
      e.preventDefault()
      @next_nicknames()

  listen_touch_end:->
    $(document).on 'touchend', (e) =>
      selection = $.selection()
      if selection
        @selection = selection

  next_nicknames: ->
    if @nicknames.length == 0
      $.ajax
        url: '/zappings'
        dataType: 'json'
        success: (json) =>
          if json.nicknames.length == 0
            Turbo.visit('/')
          else
            @nicknames = json.nicknames
            @visit(@nicknames.shift())
    else
      @visit(@nicknames.shift())

  visit: (nickname) ->
    Turbo.visit("/u/#{nickname}")
