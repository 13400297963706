jQuery ->
  post = new Post()

  $(document).on 'turbo:load, turbo:render', () ->
    unless $('body').hasClass('post')
      return
    post.init()


class Post
  constructor: ->
  init: ->
    twttr.widgets.load() if rack_env != 'test' && window.twttr

    @listen()
    if $('body').hasClass('post-form')
      if navigator.userAgent.match(/(iPod|iPhone|iPad)/)
        location.hash = '#new_post'
      $('form#new_post, form#edit_post').find('#post_content').focus()

  listen: ->
    @listen_preview()
    @listen_submit()
    @listen_close()
    @listen_content() if window.localStorage
    @listen_mail_regenerator()

  listen_preview: ->
    $('a.preview').on 'click', (e) ->
      e.preventDefault()
      post_content = $('#new_post textarea[name="post[content]"]').val()
      $('#preview_post input[name="post[content]"]').val(post_content)
      $('#preview_post').submit()

  listen_submit: ->
    $(document).on 'submit', (e) ->
      $(window).off 'beforeunload'

  listen_close: ->
    $(window).on 'beforeunload', (e) ->
      return if $('#js-post-content').val() is ''
      $('form[data-close-confirmation]').attr('data-close-confirmation')

  listen_content: ->
    expires = new Date(window.localStorage.getItem('poem.expire'))
    if (new Date() < expires)
      $('textarea[name="post[content]"]').val(window.localStorage.getItem('poem.content'))

    $('textarea[name="post[content]"]').on 'keyup', (e) ->
      window.localStorage.setItem('poem.content', $(e.currentTarget).val())
      expires = new Date()
      expires.setMinutes((new Date()).getMinutes() + 30)
      window.localStorage.setItem('poem.expire', expires)

    $(document).on 'submit', (e) ->
      window.localStorage.removeItem('poem.content')
      window.localStorage.removeItem('poem.expire')

  listen_mail_regenerator: ->
    $('#js-mail-regenerator').on 'ajax:success', (e) ->
      $('#js-email-to-post').html(e.detail[0].user.email_to_post).addClass('flash')

      setTimeout ->
        $('#js-email-to-post').removeClass('flash')
      , 300
