jQuery ->
  themeColor = new ThemeColor

  $(document).on 'turbo:load, turbo:render', () ->
    unless $('body').hasClass('edit-theme-color')
      return
    themeColor.init()

class ThemeColor
  constructor: ->

  init: ->
    @listen()
    $('input[type=radio]:checked').change()

  listen: ->
    $(document).on 'change', 'input[type=radio]', (e) =>
      $('input[type=radio]').closest('li').removeClass('checked')
      $(e.currentTarget).closest('li').addClass('checked')
