const images = require.context('../images/', true)
const fonts = require.context('../fonts/', true)

window.PPLOG = {};
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import "@hotwired/turbo-rails";
import "../ios_shim";
import "../turbo";

import Rails from '@rails/ujs';
Rails.start();
import 'bootstrap-sass/assets/javascripts/bootstrap';

// TODO: 使っているのか含めて整理したい
import 'vendor/Chart.min';
import 'vendor/cheet.min';
import 'vendor/jquery.hotkeys';
import 'vendor/jquery.onscreen';
import 'vendor/jquery.selection';

// TODO: CoffeeScript をやめる
import 'coffee/bootstrap_init';
import 'coffee/common';
import 'coffee/esarea';
import 'coffee/form';
import 'coffee/following';
import 'coffee/keyboard_shortcut';
import 'coffee/mathjax';
import 'coffee/notification_message';
import 'coffee/post';
import 'coffee/star';
import 'coffee/theme_colors';
import 'coffee/zapping';
