jQuery ->
  following = new Following

class Following
  trigger_hover_event: true
  follow_toggle_selector: 'form#follow, form#unfollow'
  constructor: ->
    @init()

  init: ->
    @listen()

  listen: ->
    @listen_follow()
    @listen_following()

  listen_follow: ->
    $(document).on 'ajax:success', @follow_toggle_selector, (e) =>
      $(@follow_toggle_selector).toggleClass('hidden')
      @trigger_hover_event = false unless rack_env == 'test'
      return

  listen_following: ->
    $('.followings-button .btn-info').on 'mouseenter', (e) =>
      return unless @trigger_hover_event
      $(e.currentTarget).hide()
      $('.followings-button .btn-danger').show()

    $('.followings-button .btn-info').on 'mouseleave', (e) =>
      @trigger_hover_event = true

    $('.followings-button .btn-danger').on 'mouseleave', (e) =>
      $(e.currentTarget).hide()
      $('.followings-button .btn-info').show()
