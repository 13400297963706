jQuery ->
  $(document).on 'click', 'a.ignore', (e) ->
    e.preventDefault()

  $(document).on 'submit', 'form.search_poem', (e) ->
    e.preventDefault()
    nickname = $(@).find('input[name="nickname"]').val().replace(/@/, '')
    return unless nickname
    location.href = "/u/#{nickname}"

  $(document).on 'click', '.star-toggle a.trigger', (e) ->
    e.preventDefault()
    $(e.currentTarget).closest('.star-container').find('.star-toggle').toggleClass('hidden')

  $(document).on 'click', '.star-toggle a.trigger.open', (e) ->
    scroll_top = $('body').scrollTop()

  $(document).on 'click', '.star-toggle a.trigger.close', (e) ->
    $li_post = $(e.currentTarget).closest('.js-user-post')
    $(window).scrollTop($li_post.offset().top) unless $li_post.find('.user-name:first').is(':onScreen')

  if navigator.userAgent.match(/(iPod|iPhone|iPad)/)
    if window.navigator.language.match /ja/
      document.title = 'ポエム'
    else
      document.title = 'Poem'

  $(document).on 'click', '.js-followee', (e) ->
    # 自分で購読していることがあるので、URLで判断する
    url = $(e.currentTarget).attr('href')
    $("a[href='#{url}']").removeClass('new-icon')

  $(document).on 'turbo:load', ->
    if $('blockquote.instagram-media').length > 0
      window.instgrm.Embeds.process()
