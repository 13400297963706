jQuery ->
  keyboardShortcut = new KeyboardShortcut

class KeyboardShortcut
  faces: null
  constructor: ->
    @init()

  init: ->
    @listen()
    @faces = ['( ˘ω˘)',
      '٩( ˘ω˘ )و',
      '₍₍ (̨̡ ‾᷄⌂‾᷅)̧̢ ₎₎',
      '(εωз)',
      'ポエ＼＼\\ ٩( ˘ω˘ )و //／／ポエ',
      '₍₍ (̨̡ ˘ω˘)̧̢ ₎₎  ₍₍ (̨̡˘ω˘ )̧̢ ₎₎',
      'ヾ(*’ω’*)ﾉﾞ',
      '₍₍ (̨̡ ‾᷄⌂‾᷅)̧̢ ₎₎',
      '|˘ω˘)"'
      ]

  listen: ->
    cheet '↑ ↑ ↓ ↓ ← → ← → b a', ->
      $('.zapping-button a i').addClass('icon-shachihoko')
      $('.zapping-button a').addClass('clicked')
      setTimeout(->
        $('.zapping-button a').removeClass('clicked')
      , 300)

    $(document).on 'keydown', 'body', (e) =>
      return if e.metaKey
      return if e.ctrlKey
      return if e.altKey
      return if e.shiftKey
      return if $('input, textarea, selectbox').is(':focus')
      switch e.keyCode
        when 70,90 # f z
          $('.zapping-button a').click()
        when 68 # d
          document.querySelector('a.post-star:not(.disabled)').click()
        when 72 # h
          location.href = '/'
        when 78 # n
          location.href = '/my/posts/new'
        when 80 # p
          $('head title').html(@faces[Math.floor(Math.random() * @faces.length)])
        when 83 # s
          document.querySelector('form#follow:not(.hidden) .follow.btn').click()
        when 74 # j
          window.scrollBy(0, 15)
        when 75 # k
          window.scrollBy(0,-15)
