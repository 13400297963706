document.addEventListener 'turbo:load', ->
  imageField = document.querySelector('#js-upload-image')
  if (!imageField)
    return

  document.querySelector('form').addEventListener 'drop', (e) ->
    e.preventDefault()
    e.stopPropagation()
    document.querySelector('#js-upload-image').files = e.dataTransfer.files
    file = document.querySelector('#js-upload-image').files[0]
    previewUploadImage(file)

  document.querySelector('#js-upload-image').addEventListener 'change', (e) ->
    file = e.target.files[0]
    previewUploadImage(file)

  previewUploadImage = (file) ->
    document.querySelector('#js-image-remove').setAttribute('value', '')
    reader = new FileReader()
    preview = document.querySelector('#js-upload-image-preview')

    reader.onload = ((file) ->
      return (e) ->
        img = preview.querySelector('img') || document.createElement( 'img' )
        img.setAttribute('class', 'image-responsive')
        img.setAttribute('src',  e.target.result)
        img.setAttribute('width', '100px')
        img.setAttribute('title',  file.name)
        preview.appendChild(img)
        preview.classList.remove('hidden')
    )(file)
    reader.readAsDataURL(file)

  document.querySelector('#js-upload-image-remove').addEventListener 'click', (e) ->
    document.querySelector('#js-upload-image').value = ''
    document.querySelector('#js-image-remove').setAttribute('value', '1')
    document.querySelector('#js-upload-image-preview').classList.add('hidden')
